angular.module("app").
    factory("_appStorage", function(_localStorage, CONFIG) {
        return {
            get: function (key) {
                return _localStorage.get(CONFIG.localStoragePrefix + key);
            },
            set: function (key, value) {
                _localStorage.set(CONFIG.localStoragePrefix + key, value);
            },
            remove: function (key) {
                _localStorage.remove(CONFIG.localStoragePrefix + key);
            }
        }
    });